export const defaultTheme = {
  fontColorBlack: "#000000",
  colorWhite: "#ffffff",
  backgroundColorMain: "#4d7c83",
  backgroundColorBlue: "#d8ecfe",
  panelBackgroundColor: "#88aa4d",
  panelBackgroundColorDanger: "#f75531",
  buttonColor: "#fc9b27",
  buttonColorHoverActive: "#fc7427",
  pagePadding: "90px",
  indent: "20px",
  fontFamily: '"Inter", "Arial", sans-serif',
  fontSizeDefault: "18px",
  lineHeightDefault: 1.5,
  pageWidth: "1280px",
  headerHeight: "80px",
  footerHeight: "80px",
  isMobile: "320px",
  isTablet: "768px",
  isDesktop: "1440px",
};
